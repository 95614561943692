import {
  CSN_SEARCH_SIMPLE_CLICK_FACT_TYPE,
  FACT_VERSION,
  SEARCH_RESULTS_PAGE,
  getPageUrl
} from '@/utils/analyticsClientHelper';

import type { SimpleClickFact } from '@/analytics/models/cventAnalytics/SimpleClickFact';
import type { MapView } from '@/constants/mapConstants';

export function getSimpleClickFact(
  clickEntity: string,
  clickType: string,
  searchResultRank: number | 'null',
  venueId: string,
  mapView: MapView | 'null',
  searchTerm: string,
  diamondLevel?: string,
  fastResponder?: string,
  activeFilters?: string,
  micrositeId?: string,
  micrositePageId?: string,
  metadata?: string,
  page?: string,
  factVersion?: string
): SimpleClickFact {
  return {
    click_entity: clickEntity,
    click_type: clickType,
    active_filters: activeFilters ?? 'null',
    diamond_level: diamondLevel ?? 'null',
    search_term: searchTerm,
    venue_id: venueId,
    fast_responder: fastResponder ?? 'null',
    map_view: mapView,
    page_url: getPageUrl(),
    page: page ?? SEARCH_RESULTS_PAGE,
    type: CSN_SEARCH_SIMPLE_CLICK_FACT_TYPE,
    position: searchResultRank,
    microsite_id: micrositeId ?? 'null',
    microsite_page_id: micrositePageId ?? 'null',
    meta_data: metadata ?? 'null',
    ver: factVersion ?? FACT_VERSION
  };
}
